import {
  UPDATE_TICKETS_LIST,
  RESET_TICKETS_LIST,
  UPDATE_CURRENT_TICKET,
  RESET_CURRENT_TICKET,
} from '../action-types'
const initialState = {
  list: [],
  current: {},
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_TICKETS_LIST:
      return {
        ...state,
        list: payload,
      }
    case RESET_TICKETS_LIST:
      return {
        ...state,
        list: initialState.list,
      }
    case UPDATE_CURRENT_TICKET:
      return {
        ...state,
        current: payload,
      }
    case RESET_CURRENT_TICKET:
      return {
        ...state,
        current: initialState.current,
      }
    default:
      return state
  }
}
