import styled from 'styled-components'
import { Layout, Button } from 'antd'
import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 120px);
  text-align: center;
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));
  align-content: center;
`

export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
  font-weight: 500;
`

export const Text = styled.span`
  color: ${colors.blue};
`
export const HighlightText = styled.h2`
  color: ${colors.blue};
  margin-bottom: 0;
`
export const SmallText = styled.small`
  color: ${colors.blue};
`
