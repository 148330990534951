import styled, { css } from 'styled-components'
import { Radio, Checkbox } from 'antd'

const listItemMixin = css`
  text-transform: uppercase;
  font-weight: 500;
  line-height: 32px;
`
const gridMixin = css`
  width: max-content;
  margin: auto;
  display: grid;
  gap: 8px;
`
const gridColumnsMixin = css`
  grid-template-columns: auto 1fr;
  justify-items: left;
  align-items: center;
`
export const RadioGrid = styled(Radio.Group)`
  ${gridMixin}
`
export const RadioItem = styled(Radio)`
  display: grid;
  ${gridColumnsMixin}
  ${listItemMixin}
`
export const CheckboxGrid = styled(Checkbox.Group)`
  ${gridMixin}
`
export const CheckboxItem = styled(Checkbox)`
  display: grid;
  ${gridColumnsMixin};
  ${listItemMixin}
  margin-left: 0 !important;
`
export const SwitchGrid = styled.div`
  ${gridMixin}
  ${gridColumnsMixin}
`
export const SwitchLabel = styled.span`
  ${listItemMixin}
`
