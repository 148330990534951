import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const getAllCookies = () => {
  return cookies.getAll()
}
export const getCookie = name => {
  return cookies.get(name)
}
export const setCookie = (name, value, options) => {
  return cookies.set(name, value, options)
}
