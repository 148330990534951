import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getTickets, callTicket } from 'Actions/ticket-actions'

import Loader from 'Components/Loader'
import Button from 'Components/Button'
import TicketModal from 'Components/TicketModal'
import { Text, HighlightText } from 'Common/styled'
import { NEXT_CALL_UNAVAILABLE_STATUSES } from 'Common/constants'

class Manage extends Component {
  static propTypes = {
    callTicket: PropTypes.func,
    getTickets: PropTypes.func,
    tickets: PropTypes.arrayOf(PropTypes.object),
  }
  state = {
    isLoading: true,
    isModalVisible: false,
  }
  componentDidMount() {
    const { history, managingQueues, getTickets } = this.props
    
    if (!managingQueues) {
      return history.push('/')
    }

    getTickets().then(() => {
      this.startTimer()
      this.setState({ isLoading: false })
    })
  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  startTimer = () => {
    const { getTickets } = this.props
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(() => getTickets(), 15000)
  }
  onCallTicket = id => {
    const { history, callTicket } = this.props
    this.setState({ isLoading: true })
    callTicket(id).then(calledTicketId => {
      if (calledTicketId) {
        history.push(`/ticket/${calledTicketId}`)
      } else {
        this.setState({ isLoading: false })
      }
    })
  }
  onCallSpecificTicket = () => {
    const { selectedTicket } = this.state
    this.onCloseModal()
    this.onCallTicket(selectedTicket)
  }
  onOpenModal = () => this.setState({ isModalVisible: true })
  onCloseModal = () => this.setState({ isModalVisible: false })
  onSelectTicket = id => this.setState({ selectedTicket: id })

  renderCallButtons() {
    const { tickets } = this.props
    if (tickets.length === 0)
      return (
        <Text>
          <FormattedMessage id={'manage.noTickets'} />
        </Text>
      )
    return (
      <>
        <Text>
          <FormattedMessage id={'manage.text'} />
        </Text>
        <HighlightText>{tickets.length}</HighlightText>
        <Button
          type={'primary'}
          onClick={() => this.onCallTicket()}
          disabled={tickets.every(t =>
            NEXT_CALL_UNAVAILABLE_STATUSES.includes(t.status)
          )}>
          <FormattedMessage id={'manage.button.callNext'} />
        </Button>
        <Button type={'primary'} onClick={this.onOpenModal}>
          <FormattedMessage id={'manage.button.callSpecific'} />
        </Button>
      </>
    )
  }
  render() {
    const { isLoading, isModalVisible, selectedTicket } = this.state
    const { history, tickets } = this.props
    if (isLoading) return <Loader />
    return (
      <>
        {this.renderCallButtons()}
        <Button
          type={'primary'}
          ghost
          onClick={() => history.push('/new-ticket')}>
          <FormattedMessage id={'manage.button.newTicket'} />
        </Button>
        <Divider />
        <Button type={'primary'} ghost onClick={() => history.push('/')}>
          <FormattedMessage id={'manage.button.goToHome'} />
        </Button>
        <TicketModal
          isVisible={isModalVisible}
          tickets={tickets}
          selected={selectedTicket}
          onChangeCallback={this.onSelectTicket}
          onCloseCallback={this.onCloseModal}
          onSubmitCallback={this.onCallSpecificTicket}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  tickets: state.ticket.list,
  managingQueues: state.queue.managing,
})
const mapDispatchToProps = {
  getTickets,
  callTicket,
}
export default connect(mapStateToProps, mapDispatchToProps)(Manage)
