export const TIME_FORMAT = 'mm:ss'
export const WAIT_TIME_FORMAT = 'HH:mm:ss'

export const QUEUE_OPEN_STATUS = 'OPEN'
export const QUEUE_CLOSE_STATUS = 'CLOSE'

export const QUEUE_SHORT_WAIT_TYPE = 'SHORT'
export const QUEUE_LONG_WAIT_TYPE = 'LONG'

export const TICKET_CALLING_STATUS = 'CALLING'
export const TICKET_REMINDED_STATUS = 'REMINDED'
export const NEXT_CALL_UNAVAILABLE_STATUSES = [
  TICKET_CALLING_STATUS,
  TICKET_REMINDED_STATUS,
]

export const TICKET_CLOSE_OK = 'COMPLETED'
export const TICKET_CLOSE_KO = 'NO SHOW'
