import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getQueues, updateManagingQueues } from 'Actions/queue-actions'

import Loader from 'Components/Loader'
import StoreName from 'Components/StoreName'
import QueuesList from 'Components/QueuesList'
import Button from 'Components/Button'
import { Text } from 'Common/styled'

class Home extends Component {
  static propTypes = {
    getQueues: PropTypes.func,
    managingQueues: PropTypes.array,
    queues: PropTypes.arrayOf(PropTypes.object),
    updateManagingQueues: PropTypes.func,
    user: PropTypes.object,
  }
  state = {
    isLoading: true,
  }
  componentDidMount() {
    const { getQueues } = this.props
    getQueues().then(() => this.setState({ isLoading: false }))
  }
  renderQueueManager() {
    const { history } = this.props
    return (
      <>
        <Divider style={{ margin: 8 }} />
        <Button
          type={'primary'}
          ghost
          onClick={() => history.push('/queue-manager')}>
          <FormattedMessage id={'home.button.queueManager'} />
        </Button>
      </>
    )
  }
  renderQueues() {
    const {
      history,
      user,
      queues,
      managingQueues,
      updateManagingQueues,
    } = this.props
    return (
      <>
        <StoreName>{queues[0].storeName}</StoreName>
        <Text>
          <FormattedMessage id={'home.text'} />
        </Text>
        <QueuesList
          type={'checkbox'}
          queues={queues}
          selected={managingQueues}
          onChangeCallback={updateManagingQueues}
        />
        <Button
          type={'primary'}
          disabled={!managingQueues}
          onClick={() => history.push('/manage')}>
          <FormattedMessage id={'home.button.manage'} />
        </Button>
        {user.queueManager ? this.renderQueueManager() : null}
      </>
    )
  }
  render() {
    const { isLoading } = this.state
    const { queues } = this.props
    if (isLoading) return <Loader />
    return queues.length > 0 ? (
      this.renderQueues()
    ) : (
      <FormattedMessage id={'home.noQueue'} />
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  queues: state.queue.list,
  managingQueues: state.queue.managing,
})
const mapDispatchToProps = {
  getQueues,
  updateManagingQueues,
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
