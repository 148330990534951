import { VALIDATE_TOKEN } from '../action-types'
export default (state = {}, { type, payload }) => {
  switch (type) {
    case VALIDATE_TOKEN:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}
