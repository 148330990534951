import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Auth from 'Containers/Auth'
import Home from 'Containers/Home'
import QueueManager from 'Containers/QueueManager'
import Manage from 'Containers/Manage'
import ManageTicket from 'Containers/ManageTicket'
import NewTicket from 'Containers/NewTicket'
import QueueStatus from 'Containers/QueueStatus'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import FeedbackButton from 'Components/FeedbackButton'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class App extends Component {
  static propTypes = {
    user: PropTypes.object,
  }

  componentDidMount() {
    const { user } = this.props

    this.tangram = new window.Tangram({
      productId: 'a4a39698-ed22-4487-ae2d-6a9e7c25accd', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.ldap,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true,
      },
    })
  }
  render() {
    const { user } = this.props
    return (
      <WhiteLayout>
        <Header user={user} />
        <Auth>
          <PaddedContent>
            <Switch>
              <Route component={Home} exact path={'/'} />
              <Route component={QueueManager} path={'/queue-manager'} />
              <Route component={Manage} path={'/manage'} />
              <Route component={ManageTicket} path={'/ticket/:ticketId'} />
              <Route component={NewTicket} path={'/new-ticket'} />
              <Route component={QueueStatus} path={'/queue-status/:ticketId'} />
              <Route component={Page404} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />
        <FeedbackButton onClick={() => this.tangram.open()} />
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user,
})
export default connect(mapStateToProps)(App)
