import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider, Alert } from 'antd'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import moment from 'moment'

import { getTicket, closeTicket, remindTicket } from 'Actions/ticket-actions'

import Loader from 'Components/Loader'
import Ticket from 'Components/Ticket'
import Button from 'Components/Button'
import { Text } from 'Common/styled'

import {
  TIME_FORMAT,
  WAIT_TIME_FORMAT,
  TICKET_CLOSE_OK,
  TICKET_CLOSE_KO,
} from 'Common/constants'

class ManageTicket extends Component {
  static propTypes = {
    closeTicket: PropTypes.func,
    getTicket: PropTypes.func,
    remindTicket: PropTypes.func,
    ticket: PropTypes.object,
  }
  state = {
    isLoading: true,
    time: moment().startOf('day'),
  }
  componentDidMount() {
    const { getTicket } = this.props
    getTicket().then(ticket => {
      this.startTimer()
      this.startWaitTimer(ticket.creationTimestamp)
      this.setState({ isLoading: false })
    })
  }
  componentWillUnmount() {
    clearInterval(this.timer)
    clearInterval(this.waitTimer)
  }
  startTimer() {
    if (this.timer) clearInterval(this.timer)

    this.timer = setInterval(
      () => this.setState(prevState => ({ time: prevState.time.add(1, 's') })),
      1000
    )
  }
  startWaitTimer(creationTimestamp) {
    if (this.waitTimer) clearInterval(this.waitTimer)

    this.getWaitTime(creationTimestamp)
    this.waitTimer = setInterval(
      () => this.getWaitTime(creationTimestamp),
      1000
    )
  }
  getWaitTime(creationTimestamp) {
    const diff = moment().diff(moment(creationTimestamp))
    this.setState({ waitTime: moment.utc(diff) })
  }
  onCloseTicket = type => {
    const { history, ticket, closeTicket } = this.props
    this.setState({ isLoading: true })
    closeTicket(ticket.id, type).then(res => {
      if (res === 200) {
        history.push('/manage')
      } else {
        this.setState({ isLoading: false })
      }
    })
  }
  onRemindTicket = () => {
    const { history, ticket, remindTicket } = this.props
    this.setState({ isLoading: true })
    remindTicket(ticket.id).then(res => {
      if (res === 200) {
        history.push('/manage')
      } else {
        this.setState({ isLoading: false })
      }
    })
  }
  renderReminder() {
    const { ticket } = this.props
    if (ticket.reminderTimestamp) {
      return (
        <Alert
          type={'warning'}
          banner
          message={<FormattedMessage id={'manageTicket.reminder.text'} />}
        />
      )
    } else {
      return (
        <Button type={'primary'} onClick={this.onRemindTicket}>
          <FormattedMessage id={'manageTicket.button.remind'} />
        </Button>
      )
    }
  }
  render() {
    const { isLoading, time, waitTime } = this.state
    const { ticket } = this.props
    const { ticketNumber, hasPhoneNumber } = ticket
    if (isLoading) return <Loader />
    return (
      <>
        <Ticket number={ticketNumber} />
        <Text>
          <FormattedMessage id={'manageTicket.waitTime.text'} />{' '}
          {waitTime.format(WAIT_TIME_FORMAT)}
        </Text>
        {hasPhoneNumber ? this.renderReminder() : null}
        <Divider />
        <Text>
          <FormattedMessage id={'manageTicket.time.text'} />{' '}
          {time.format(TIME_FORMAT)}
        </Text>
        <Button
          type={'primary'}
          ghost
          onClick={() => this.onCloseTicket(TICKET_CLOSE_OK)}>
          <FormattedMessage id={'manageTicket.button.customerOk'} />
        </Button>
        <Button
          type={'danger'}
          ghost
          onClick={() => this.onCloseTicket(TICKET_CLOSE_KO)}>
          <FormattedMessage id={'manageTicket.button.customerKo'} />
        </Button>
      </>
    )
  }
}
const mapStateToProps = state => ({
  ticket: state.ticket.current,
})
const mapDispatchToProps = {
  getTicket,
  closeTicket,
  remindTicket,
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageTicket)
