import moment from 'moment'
import { VALIDATE_TOKEN } from '../action-types'
import axiosConfig from 'Common/axiosConfig'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'
import { setCookie } from 'Common/cookies'

export const validateToken = token => async dispatch => {
  try {
    const res = await api.user.authenticate(token)
    const { cookie, user } = res.data
    setCookie(cookie.name, cookie.value, {
      expires: moment().add(1, 'd').toDate(),
    })
    axiosConfig.defaults.headers.Authorization = `Bearer ${token}`
    return dispatch({ type: VALIDATE_TOKEN, payload: user })
  } catch (error) {
    return handleServerError(error)
  }
}
