import {
  UPDATE_TICKETS_LIST,
  RESET_TICKETS_LIST,
  UPDATE_CURRENT_TICKET,
  RESET_CURRENT_TICKET,
  GET_TICKETS,
  GET_TICKET,
  CALL_TICKET,
  CLOSE_TICKET,
  REMIND_TICKET,
  CREATE_TICKET,
} from '../action-types'
import store from 'Store'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const updateTicketsList = ticketsList => {
  return { type: UPDATE_TICKETS_LIST, payload: ticketsList }
}
export const resetTicketsList = () => {
  return { type: RESET_TICKETS_LIST }
}
export const updateCurrentTicket = ticket => {
  return { type: UPDATE_CURRENT_TICKET, payload: ticket }
}
export const resetCurrentTicket = () => {
  return { type: RESET_CURRENT_TICKET }
}

export const getTickets = () => async dispatch => {
  const { queue, user } = store.getState()
  const params = { queueIds: queue.managing.join(), username: user.email }
  try {
    const res = await api.ticket.list(params)
    const { data, status } = res
    dispatch({ type: GET_TICKETS })
    if (status === 204) return dispatch(resetTicketsList())
    return dispatch(updateTicketsList(data.backlog))
  } catch (error) {
    return handleServerError(error)
  }
}
export const getTicket = () => async dispatch => {
  const { id } = store.getState().ticket.current
  try {
    const res = await api.ticket.retrieve(id)
    const ticket = res.data.ticket
    dispatch({ type: GET_TICKET })
    dispatch(updateCurrentTicket(ticket))
    return ticket
  } catch (error) {
    return handleServerError(error)
  }
}
export const callTicket = id => async dispatch => {
  const { user, queue } = store.getState()
  const body = {
    id: id || 0,
    queueIds: queue.managing.join(),
    username: user.email,
  }
  try {
    const res = await api.ticket.call(body)
    const { calledTicketId } = res.data
    dispatch({ type: CALL_TICKET })
    dispatch(updateCurrentTicket({ id: calledTicketId }))
    return calledTicketId
  } catch (error) {
    return handleServerError(error)
  }
}
export const closeTicket = (id, type) => async dispatch => {
  const { email } = store.getState().user
  const body = {
    id,
    type,
    username: email,
  }
  try {
    await api.ticket.close(body)
    dispatch({ type: CLOSE_TICKET })
    dispatch(resetCurrentTicket())
    return 200
  } catch (error) {
    return handleServerError(error)
  }
}
export const remindTicket = id => async dispatch => {
  const { email } = store.getState().user
  const body = { id, username: email }
  try {
    await api.ticket.remind(body)
    dispatch({ type: REMIND_TICKET })
    dispatch(resetCurrentTicket())
    return 200
  } catch (error) {
    return handleServerError(error)
  }
}
export const createTicket = queueId => async dispatch => {
  const { email } = store.getState().user
  const body = { queueId, username: email }
  try {
    const res = await api.ticket.create(body)
    const ticket = res.data.createdTicket
    dispatch({ type: CREATE_TICKET })
    dispatch(updateCurrentTicket(ticket))
    return ticket.id
  } catch (error) {
    return handleServerError(error)
  }
}
