import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Select } from 'antd'
import { injectIntl } from 'react-intl'

const TicketModal = props => {
  const {
    intl,
    isVisible,
    tickets,
    selected,
    onSubmitCallback,
    onCloseCallback,
    onChangeCallback,
  } = props
  return (
    <Modal
      centered
      visible={isVisible}
      onOk={onSubmitCallback}
      onCancel={onCloseCallback}
      okButtonProps={{ disabled: !selected }}
      okText={intl.formatMessage({ id: 'ticketModal.okText' })}
      cancelText={intl.formatMessage({ id: 'ticketModal.cancelText' })}>
      <Select
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().includes(input.toLowerCase())
        }
        size={'large'}
        style={{ width: '90%' }}
        placeholder={intl.formatMessage({
          id: 'ticketModal.select.placeholder',
        })}
        onChange={onChangeCallback}
        defaultValue={selected}>
        {tickets.map(t => {
          const { id, ticketNumber, queueName } = t
          return (
            <Select.Option key={id}>
              {`[${ticketNumber}] ${queueName}`}
            </Select.Option>
          )
        })}
      </Select>
    </Modal>
  )
}
TicketModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onChangeCallback: PropTypes.func,
  onCloseCallback: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  selected: PropTypes.string,
  tickets: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default injectIntl(TicketModal)
