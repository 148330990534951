import API from './axiosConfig'

export default {
  user: {
    authenticate: token =>
      API.get('/collaborators_user/details', {
        headers: { Authorization: `Bearer ${token}` },
      }),
  },
  queue: {
    list: storeId =>
      API.get('/collaborators_queue/list', { params: { storeId } }),
    manage: ({ queueId, action, username }) =>
      API.post('/collaborators_queue/manage', { queueId, action, username }),
  },
  ticket: {
    list: ({ queueIds, username }) =>
      API.get('/collaborators_ticket/backlog', {
        params: { queueIds, username },
      }),
    call: ({ id, queueIds, username }) =>
      API.post('/collaborators_ticket/call', { id, queueIds, username }),
    retrieve: id =>
      API.get('/collaborators_ticket/retrieve', { params: { id } }),
    close: ({ id, type, username }) =>
      API.post('/collaborators_ticket/close', { id, type, username }),
    remind: ({ id, username }) =>
      API.post('/collaborators_ticket/reminder', { id, username }),
    create: ({ queueId, username }) =>
      API.post('/mixed_ticket/create', {
        queueId,
        createdBy: username,
        creationType: 'ASSISTED',
      }),
  },
  sms: {
    submit: ({ id, phoneNumber }) =>
      API.post('/mixed_sms/submit', { id, phoneNumber }),
  },
}
