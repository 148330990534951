import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Divider } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getTicket } from 'Actions/ticket-actions'
import { sendPhoneNumber } from 'Actions/app-actions'

import Loader from 'Components/Loader'
import Button from 'Components/Button'
import Ticket from 'Components/Ticket'
import QueuePosition from 'Components/QueuePosition'
import { Text, SmallText, HighlightText } from 'Common/styled'

import { QUEUE_SHORT_WAIT_TYPE, QUEUE_LONG_WAIT_TYPE } from 'Common/constants'

class QueueStatus extends Component {
  static propTypes = {
    currentTicket: PropTypes.object,
    getTicket: PropTypes.func,
  }
  state = {
    isLoading: true,
  }
  componentDidMount() {
    const { currentTicket } = this.props
    const ticketId = currentTicket.id
    if (ticketId) {
      this.getTicket(ticketId)
    }
  }
  getTicket(ticketId) {
    const { history, getTicket } = this.props
    getTicket(ticketId).then(res => {
      if (res === 404) return history.push('/')
      this.setState({ isLoading: false, smsSuccess: false })
    })
  }
  onSubmitPhone = () => {
    const { currentTicket, sendPhoneNumber } = this.props
    const phoneNumber = document.getElementById('phone').value
    if (phoneNumber) {
      this.setState({ isLoading: true })
      sendPhoneNumber({ id: currentTicket.id, phoneNumber }).then(res => {
        const newState = { isLoading: false }
        if (res === 200) newState.smsSuccess = true
        this.setState(newState)
      })
    }
  }
  renderFeedback() {
    return (
      <>
        <HighlightText>
          <FormattedMessage id={'queueStatus.phoneFeedback.title'} />
        </HighlightText>
        <Text>
          <FormattedMessage id={'queueStatus.phoneFeedback.text_1'} />
        </Text>
        <Text>
          <FormattedMessage id={'queueStatus.phoneFeedback.text_2'} />
        </Text>
        {this.renderButtons()}
      </>
    )
  }
  renderButtons() {
    const { history } = this.props
    return (
      <>
        <Divider style={{ margin: 8 }} />
        <Button type={'primary'} ghost onClick={() => history.push('/manage')}>
          <FormattedMessage id={'queueStatus.button.goBack'} />
        </Button>
      </>
    )
  }
  renderQueueWait() {
    const { intl, currentTicket } = this.props
    const { waitType } = currentTicket
    if (waitType === QUEUE_SHORT_WAIT_TYPE) {
      return (
        <>
          <Text>
            <FormattedMessage
              id={'queueStatus.shortWait.text'}
              values={{ linebreak: <br /> }}
            />
          </Text>
        </>
      )
    }
    if (waitType === QUEUE_LONG_WAIT_TYPE) {
      return (
        <>
          <Text>
            <FormattedMessage
              id={'queueStatus.longWait.text'}
              values={{ linebreak: <br /> }}
            />
          </Text>
          <Input
            id={'phone'}
            type={'tel'}
            size={'large'}
            addonBefore={intl.formatMessage({ id: 'queueStatus.phone.prefix' })}
          />
          <SmallText>
            <FormattedMessage id={'queueStatus.gdpr.text'} />
            <a
              href={intl.formatMessage({ id: 'queueStatus.gdpr.link' })}
              target={'_blank'}
              rel={'noopener noreferrer'}>
              <FormattedMessage id={'queueStatus.gdpr.linkText'} />
            </a>
          </SmallText>
          <Button type={'primary'} onClick={this.onSubmitPhone}>
            <FormattedMessage id={'queueStatus.button.submitPhone'} />
          </Button>
        </>
      )
    }
  }
  render() {
    const { isLoading, smsSuccess } = this.state
    const { currentTicket } = this.props
    const { ticketNumber, queuePositionImage } = currentTicket
    if (isLoading) return <Loader />
    if (smsSuccess) return this.renderFeedback()
    return (
      <>
        <div>
          <HighlightText>
            <FormattedMessage id={'queueStatus.text'} />
          </HighlightText>
          <QueuePosition positionImg={queuePositionImage} />
        </div>
        <Ticket number={ticketNumber} />
        {this.renderQueueWait()}
        {this.renderButtons()}
      </>
    )
  }
}
const mapStateToProps = state => ({
  currentTicket: state.ticket.current,
})
const mapDispatchToProps = {
  getTicket,
  sendPhoneNumber,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(QueueStatus))
