import styled from 'styled-components'
import { colors } from 'Common/constants'
export const StyledUser = styled.div`
  & > small {
    color: ${colors.main};
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 0.5rem;
  }
`
