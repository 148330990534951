export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

export const GET_QUEUES = 'GET_QUEUES'
export const UPDATE_MANAGING_QUEUES = 'UPDATE_MANAGING_QUEUES'
export const MANAGE_QUEUE = 'MANAGE_QUEUE'

export const UPDATE_TICKETS_LIST = 'UPDATE_TICKETS_LIST'
export const RESET_TICKETS_LIST = 'RESET_TICKETS_LIST'
export const UPDATE_CURRENT_TICKET = 'UPDATE_CURRENT_TICKET'
export const RESET_CURRENT_TICKET = 'RESET_CURRENT_TICKET'
export const GET_TICKETS = 'GET_TICKETS'
export const GET_TICKET = 'GET_TICKET'
export const CALL_TICKET = 'CALL_TICKET'
export const CLOSE_TICKET = 'CLOSE_TICKET'
export const REMIND_TICKET = 'REMIND_TICKET'
export const CREATE_TICKET = 'CREATE_TICKET'

export const SEND_PHONE_NUMBER = 'SEND_PHONE_NUMBER'