import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { createTicket } from 'Actions/ticket-actions'

import Loader from 'Components/Loader'
import StoreName from 'Components/StoreName'
import QueuesList from 'Components/QueuesList'
import Button from 'Components/Button'
import { Text } from 'Common/styled'

class NewTicket extends Component {
  static propTypes = {
    createTicket: PropTypes.func,
    queues: PropTypes.arrayOf(PropTypes.object),
  }
  state = {
    isLoading: false,
  }
  onSubmitTicket = () => {
    const { selectedQueue } = this.state
    const { history, createTicket } = this.props
    this.setState({ isLoading: true })
    createTicket(selectedQueue).then(ticketId => {
      history.push(`/queue-status/${ticketId}`)
    })
  }
  render() {
    const { isLoading, selectedQueue } = this.state
    const { history, queues } = this.props
    if (isLoading) return <Loader />
    return (
      <>
        <StoreName>{queues[0].storeName}</StoreName>
        <Text>
          <FormattedMessage id={'newTicket.text'} />
        </Text>
        <QueuesList
          type={'radio'}
          queues={queues}
          onChangeCallback={e =>
            this.setState({ selectedQueue: e.target.value })
          }
        />
        <Button
          type={'primary'}
          disabled={!selectedQueue}
          onClick={this.onSubmitTicket}>
          <FormattedMessage id={'newTicket.button.newTicket'} />
        </Button>
        <Button type={'primary'} ghost onClick={() => history.push('/manage')}>
          <FormattedMessage id={'newTicket.button.goBack'} />
        </Button>
      </>
    )
  }
}
const mapStateToProps = state => ({
  queues: state.queue.list,
})
const mapDispatchToProps = {
  createTicket,
}
export default connect(mapStateToProps, mapDispatchToProps)(NewTicket)
