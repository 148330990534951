import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'antd'

import {
  CheckboxGrid,
  CheckboxItem,
  RadioGrid,
  RadioItem,
  SwitchGrid,
  SwitchLabel,
} from './styled'
import { QUEUE_OPEN_STATUS, QUEUE_CLOSE_STATUS } from 'Common/constants'

const QueuesList = props => {
  const { type, queues, selected, onChangeCallback } = props
  switch (type) {
    case 'checkbox':
      return (
        <CheckboxGrid onChange={onChangeCallback} defaultValue={selected}>
          {queues.map(q => {
            const { queueId, queueName, queueLength } = q
            return (
              <CheckboxItem key={queueId} value={queueId}>
                {queueName} ({queueLength})
              </CheckboxItem>
            )
          })}
        </CheckboxGrid>
      )
    case 'switch':
      return (
        <SwitchGrid>
          {queues.map(q => {
            const { queueId, queueName, status } = q
            return (
              <Fragment key={queueId}>
                <Switch
                  defaultChecked={status === QUEUE_OPEN_STATUS}
                  onChange={checked => onChangeCallback(checked, queueId)}
                />
                <SwitchLabel>{queueName}</SwitchLabel>
              </Fragment>
            )
          })}
        </SwitchGrid>
      )
    case 'radio':
      return (
        <RadioGrid onChange={onChangeCallback}>
          {queues.map(q => {
            const { queueId, queueName, status } = q
            return (
              <RadioItem
                key={queueId}
                value={queueId}
                disabled={status === QUEUE_CLOSE_STATUS}>
                {queueName}
              </RadioItem>
            )
          })}
        </RadioGrid>
      )
    default:
      return ''
  }
}
QueuesList.propTypes = {
  onChangeCallback: PropTypes.func,
  queues: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.oneOfType([PropTypes.array]),
  type: PropTypes.string.isRequired,
}
export default QueuesList
