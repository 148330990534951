import { combineReducers } from 'redux'
import app from './app-reducer'
import user from './user-reducer'
import queue from './queue-reducer'
import ticket from './ticket-reducer'

const combinedReducers = combineReducers({
  app,
  user,
  queue,
  ticket,
})

export default combinedReducers
