import { notification } from 'antd'

import config from 'Common/config'
const { LOGIN_URL } = config

export const handleServerError = error => {
  if (error.response.status === 401) {
    window.location.href = LOGIN_URL
  } else {
    const status = error.response && error.response.status
    const data = error.response && error.response.data
    return notification.error({
      message: status ? `Errore ${status}` : 'Si è verificato un errore',
      description: data && data.message ? data.message : 'Riprova',
    })
  }
}
