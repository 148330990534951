import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import { StyledUser } from './styled'
const User = props => {
  const { user } = props
  const surname = user.surname && user.surname.substring(0, 1)
  return (
    <StyledUser>
      <Avatar size={'small'} icon="user" />
      <small>
        {user.name} {surname}.
      </small>
    </StyledUser>
  )
}
User.propTypes = {
  user: PropTypes.object,
}
export default User
