import { GET_QUEUES, UPDATE_MANAGING_QUEUES } from '../action-types'
const initialState = {
  list: [],
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_QUEUES:
      return {
        ...state,
        list: payload,
      }

    case UPDATE_MANAGING_QUEUES:
      return {
        ...state,
        managing: payload,
      }
    default:
      return state
  }
}
