import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { manageQueue } from 'Actions/queue-actions'

import StoreName from 'Components/StoreName'
import QueuesList from 'Components/QueuesList'
import Button from 'Components/Button'
import { Text } from 'Common/styled'

class QueueManager extends Component {
  static propTypes = {
    manageQueue: PropTypes.func,
    queues: PropTypes.arrayOf(PropTypes.object),
  }
  render() {
    const { history, queues, manageQueue } = this.props
    if (queues.length > 0)
      return (
        <>
          <StoreName>{queues[0].storeName}</StoreName>
          <Text>
            <FormattedMessage id={'queueManager.text'} />
          </Text>
          <QueuesList
            type={'switch'}
            queues={queues}
            onChangeCallback={manageQueue}
          />
          <Divider style={{ margin: 8 }} />
          <Button type={'primary'} ghost onClick={() => history.push('/')}>
            <FormattedMessage id={'queueManager.button.goBack'} />
          </Button>
        </>
      )
    return <FormattedMessage id={'queueManager.noQueue'} />
  }
}
const mapStateToProps = state => ({
  queues: state.queue.list,
})
const mapDispatchToProps = {
  manageQueue,
}
export default connect(mapStateToProps, mapDispatchToProps)(QueueManager)
