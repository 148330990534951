import {
  GET_QUEUES,
  UPDATE_MANAGING_QUEUES,
  MANAGE_QUEUE,
} from '../action-types'
import store from 'Store'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'
import { QUEUE_OPEN_STATUS, QUEUE_CLOSE_STATUS } from 'Common/constants'

export const getQueues = () => async dispatch => {
  try {
    const { storeId } = store.getState().user
    const res = await api.queue.list(storeId)
    const { data, status } = res
    if (status === 204) return
    return dispatch({ type: GET_QUEUES, payload: data.queues })
  } catch (error) {
    return handleServerError(error)
  }
}
export const updateManagingQueues = queues => {
  return {
    type: UPDATE_MANAGING_QUEUES,
    payload: queues.length > 0 ? queues : undefined,
  }
}
export const manageQueue = (checked, queueId) => async dispatch => {
  const { email } = store.getState().user
  const body = {
    queueId,
    action: checked ? QUEUE_OPEN_STATUS : QUEUE_CLOSE_STATUS,
    username: email,
  }
  try {
    await api.queue.manage(body)
    return dispatch({ type: MANAGE_QUEUE })
  } catch (error) {
    return handleServerError(error)
  }
}
